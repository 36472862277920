import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IProspect, ProspectDeletionReason, ProspectRejectionReason, ProspectStatus } from '@models/backend/prospects';
import {
    BulkOperationResult,
    IProspectResponseBody,
    IProspectsPageResponseBody,
    NoContentResponse,
} from '@models/backend/responses';
import { ProspectViewModel } from '@models/prospect';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProspectsService {
    private http = inject(HttpClient);

    getProspectData(
        advertId: string,
        searchTerm?: string,
        isBookmarked?: boolean,
        prospectStatus?: string,
        page?: number,
    ): Observable<IProspectsPageResponseBody> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set('search', searchTerm);
        }

        if (isBookmarked) {
            params = params.set('isBookmarked', 'true');
        }

        if (prospectStatus) {
            params = params.set('prospectStatus', prospectStatus);
        }

        const url = `units/${advertId}/prospects/?page=${page || 0}`;
        return this.http.get<IProspectsPageResponseBody>(url, { params });
    }

    createProspect(advertId: string, prospect: ProspectViewModel): Observable<IProspectResponseBody> {
        return this.http.post<IProspectResponseBody>(`units/${advertId}/prospects`, prospect);
    }

    copyProspects(
        advertId: string,
        targetAdvertId: string,
        prospectIds: string[],
    ): Observable<NoContentResponse | BulkOperationResult<IProspect>[]> {
        const prospectCopyBody = {
            targetAdvertId,
            prospectIds,
        };

        return this.http.post<NoContentResponse | BulkOperationResult<IProspect>[]>(
            `units/${advertId}/prospects?op=copy-prospects`,
            prospectCopyBody,
        );
    }

    updateProspect(advertId: string, prospect: ProspectViewModel): Observable<IProspect> {
        return this.http.patch<IProspect>(`units/${advertId}/prospects/${prospect.id}`, prospect);
    }

    bulkUpdateProspectStatus(
        advertId: string,
        status: ProspectStatus,
        prospects: ProspectViewModel[],
    ): Observable<NoContentResponse> {
        const body = prospects.map((p) => {
            return {
                status: status,
                id: p.id,
            };
        });

        return this.http.patch<NoContentResponse>(`units/${advertId}/prospects`, body);
    }

    bulkDeleteProspects(
        advertId: string,
        rejectionReason: ProspectDeletionReason,
        prospects: ProspectViewModel[],
    ): Observable<NoContentResponse> {
        const body = prospects.map((p) => {
            return {
                rejectionReason,
                id: p.id,
            };
        });

        return this.http.patch<NoContentResponse>(`units/${advertId}/prospects`, body);
    }

    deleteProspect(
        advertId: string,
        prospect: ProspectViewModel,
        deletionReason: ProspectDeletionReason,
    ): Observable<NoContentResponse> {
        const params = new HttpParams().set('rejectionReason', deletionReason);
        return this.http.delete<NoContentResponse>(`units/${advertId}/prospects/${prospect.id}`, { params });
    }

    rejectProspect(
        advertId: string,
        prospect: ProspectViewModel,
        shouldDelete: boolean,
        hasOffer: boolean,
        rejectionReason?: ProspectRejectionReason,
    ): Observable<NoContentResponse> {
        const url = `units/${advertId}/prospects/${prospect.id}`;
        const params = new HttpParams()
            .set('shouldDelete', shouldDelete.toString())
            .set('hasOffer', hasOffer.toString());

        const body = {
            ...prospect,
            rejectionReason,
        };

        return this.http.patch<NoContentResponse>(url, body, { params });
    }

    bulkRejectProspects(
        advertId: string,
        prospects: ProspectViewModel[],
        shouldDelete: boolean,
        hasOffer: boolean,
        rejectionReason?: ProspectRejectionReason,
    ): Observable<NoContentResponse> {
        const url = `units/${advertId}/prospects`;

        const params = new HttpParams()
            .set('shouldDelete', shouldDelete.toString())
            .set('hasOffer', hasOffer.toString());

        const body = prospects.map((p) => {
            return {
                rejectionReason,
                id: p.id,
            };
        });

        return this.http.patch<NoContentResponse>(url, body, { params: params });
    }
}
