<mat-chip-set *ngIf="advert">
    <mat-chip color="primary">{{ 'KEY_FACT_ENUMS.type.pill-' + advert.type | translate }}</mat-chip>
    <mat-chip *ngIf="advert.isPublished">{{ translatedDaysSincePublished() | async }}</mat-chip>
    <mat-chip *ngIf="advert.isReserved">{{ translatedDaysSinceReserved() | async }}</mat-chip>
    <mat-chip *ngIf="advert.hasUpcomingViewings">{{ upcomingViewingsMessage() }}</mat-chip>
    <ng-container *ngIf="isVacancyStatusEnabled">
        <mat-chip *ngIf="advert.isVacant" class="red">{{ 'SHARED_COMPONENT.VACANT' | translate }}</mat-chip>
        <mat-chip *ngIf="advert.isNotice" class="yellow">{{ 'SHARED_COMPONENT.NOTICE' | translate }}</mat-chip>
        <mat-chip *ngIf="advert.isOccupied" class="green">{{ 'SHARED_COMPONENT.OCCUPIED' | translate }}</mat-chip>
    </ng-container>
</mat-chip-set>
