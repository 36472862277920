<div class="dashboard">
    @if (isDataAvailable) {
        <section class="header">
            <article class="subtitle hello">{{ 'SHARED_COMPONENT.WELCOME_BACK' | translate }} {{ userName }}</article>
            <button mat-flat-button color="primary" class="all" routerLink="/teasers">
                {{ 'SHARED_COMPONENT.UNIT_LIST' | translate }}
            </button>
        </section>

        <section class="tabs" *featureToggle="'general-inquiry'">
            <aside
                class="tab-item"
                routerLinkActive="selected"
                routerLink="/"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                {{ 'SHARED_COMPONENT.ADVERTS' | translate }}
            </aside>

            <aside class="tab-item" routerLinkActive="selected" routerLink="/general-inquiries">
                {{ 'SHARED_COMPONENT.PURPOSE_GENERAL_INQUIRY' | translate }}
                <span> {{ generalInquiriesTotal }}</span>
            </aside>
        </section>

        @if (!isGeneralInquiryActive && hasPublishedOrBookmarked) {
            <section class="filter" [formGroup]="form">
                <div class="option">
                    <mat-form-field>
                        <mat-label>{{ 'SHARED_COMPONENT.SORT_BY' | translate }}</mat-label>
                        <mat-select formControlName="sortBy" highlightActiveFormControl>
                            <mat-option [value]="'advertId'">advert id</mat-option>
                            <mat-option [value]="'publishdate'">{{
                                'SHARED_COMPONENT.PUBLISH_DATE' | translate
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="option direction">
                    <mat-radio-group formControlName="sortDirection">
                        <mat-radio-button [value]="'ASC'">{{
                            'SHARED_COMPONENT.ASCENDING' | translate
                        }}</mat-radio-button>
                        <mat-radio-button [value]="'DESC'">{{
                            'SHARED_COMPONENT.DESCENDING' | translate
                        }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="option"></div>
                <div class="option"></div>
            </section>
        }
    }

    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<loading-indicator></loading-indicator>
