@if (uploadedImages$) {
    <div class="files-row">
        <div class="file-list" [formGroup]="imageUploadform">
            @for (item of uploadedImages; track $index) {
                <div formArrayName="items">
                    @if (imageUploadResults[$index].uploadStatus === 'inProgress') {
                        <mat-progress-bar
                            mode="determinate"
                            [value]="imageUploadResults[$index].progress"
                        ></mat-progress-bar>
                    }

                    <div [formGroupName]="$index">
                        <div class="file-item">
                            <div class="image-details">
                                <img class="{{ item.orientation }}" [src]="item.image" alt="image-{{ $index }}" />

                                <div class="file-details">
                                    <span
                                        class="name"
                                        title="{{ item.name }}"
                                        [ngClass]="{ ellipse: !isStartUploading }"
                                        >{{ item.name }}</span
                                    >
                                    <span>{{ item.size | formatFileSize }}</span>
                                </div>
                            </div>

                            <div class="container" [class.end]="isStartUploading">
                                @if (isStartUploading) {
                                    <section class="item-width txt-right">
                                        @switch (imageUploadResults[$index].uploadStatus) {
                                            @case ('inProgress') {
                                                <div class="progress-percentage">
                                                    {{ imageUploadResults[$index].progress }}%
                                                </div>
                                            }
                                            @case ('success') {
                                                <mat-icon class="success">check_circle</mat-icon>
                                            }
                                            @case ('failed') {
                                                <mat-icon class="failed">cancel</mat-icon>
                                            }
                                        }
                                    </section>
                                } @else {
                                    @if (isPropertyBuilding) {
                                        <section class="item-width">
                                            <mat-form-field>
                                                <mat-label>{{ 'SHARED_COMPONENT.TYPE_LABEL' | translate }}</mat-label>
                                                <mat-select formControlName="pictureType">
                                                    @for (tagType of imageTagsList; track $index) {
                                                        <mat-option [value]="tagType.id">
                                                            {{ tagType.label | translate }}
                                                        </mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>

                                            @if (
                                                imageUploadformControls.controls[$index].controls.pictureType.hasError(
                                                    'required'
                                                )
                                            ) {
                                                <mat-error>{{
                                                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                                                }}</mat-error>
                                            }
                                        </section>
                                    }

                                    <section class="item-width">
                                        <mat-form-field>
                                            <mat-label>{{
                                                'IMAGE_UPLOAD_ORIENTATION.orientation' | translate
                                            }}</mat-label>
                                            <mat-select formControlName="orientation">
                                                @for (orientation of orientations; track $index) {
                                                    <mat-option [value]="orientation.id">
                                                        {{ orientation.label | translate }}
                                                    </mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </section>

                                    <section class="item-width">
                                        <autocomplete
                                            formControlName="pictureDetail"
                                            placeholder="{{ 'SHARED_COMPONENT.DETAIL' | translate }}"
                                            [options]="detailsOptions"
                                        >
                                        </autocomplete>
                                    </section>

                                    @if (showExample) {
                                        <section class="example">
                                            <mat-checkbox formControlName="isExample">{{
                                                'IMAGE_TAGS.examples' | translate
                                            }}</mat-checkbox>
                                        </section>
                                    }

                                    <mat-icon
                                        class="material-icons-outlined"
                                        clickAndKeydown
                                        (actionTriggered)="remove($index)"
                                    >
                                        delete
                                    </mat-icon>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}

<div class="actions">
    <button mat-flat-button clickAndKeydown (actionTriggered)="backToAdvert()">
        {{ 'SHARED_COMPONENT.BACK_TO_ADVERT' | translate }}
    </button>

    @if (isUploadComplete) {
        <button class="backToUpload" mat-flat-button color="primary" clickAndKeydown (actionTriggered)="backToUpload()">
            {{ 'SHARED_COMPONENT.BACK_TO_UPLOAD' | translate }}
        </button>
    } @else {
        <button
            mat-flat-button
            color="primary"
            [disabled]="IsUploadValid"
            clickAndKeydown
            (actionTriggered)="uploadImagesOneByOne()"
        >
            {{ 'SHARED_COMPONENT.UPLOAD_BUTTON' | translate }}
        </button>
    }
</div>
