import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GeneralInquiryStatus, IGeneralInquiry } from '@models/backend/general-inquiry';
import { IProspect } from '@models/backend/prospects';
import { IGeneralInquiryPageResponseBody, NoContentResponse } from '@models/backend/responses';
import { Observable } from 'rxjs';

export type GeneralInquiryUpdateURequestBody = Partial<
    Pick<IGeneralInquiry, 'email' | 'message' | 'name' | 'phone' | 'status'>
>;

export type ConvertGeneralInquiryToProspectRequestBody = Pick<
    IProspect,
    | 'dateOfReceipt'
    | 'email'
    | 'householdSize'
    | 'marketingChannel'
    | 'message'
    | 'name'
    | 'netHouseholdIncome'
    | 'phone'
    | 'portalName'
    | 'preferredMoveInDate'
    | 'status'
>;

@Injectable({
    providedIn: 'root',
})
export class GeneralInquiryService {
    private httpClient = inject(HttpClient);

    getData(
        searchTerm?: string,
        status?: string,
        region?: string,
        page?: number,
    ): Observable<IGeneralInquiryPageResponseBody> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set('searchTerm', searchTerm);
        }

        if (status) {
            params = params.set('status', status);
        }

        if (region) {
            params = params.set('region', region);
        }

        return this.httpClient.get<IGeneralInquiryPageResponseBody>(`general-inquiries?page=${page || 0}`, {
            params,
        });
    }

    handleConvertGeneralInquiryToProspect(
        advertId: string,
        generalInquiryId: string,
        prospect: ConvertGeneralInquiryToProspectRequestBody,
    ): Observable<NoContentResponse> {
        return this.httpClient.post<NoContentResponse>(
            `units/${advertId}/handle/convert-general-inquiry-to-prospect/${generalInquiryId}`,
            prospect,
        );
    }

    updateGeneralInquiry(id: string, generalInquiry: GeneralInquiryUpdateURequestBody): Observable<NoContentResponse> {
        return this.httpClient.patch<NoContentResponse>(`general-inquiries/${id}`, generalInquiry);
    }

    updateGeneralInquiryStatus(status: GeneralInquiryStatus, generalInquiryId: string): Observable<NoContentResponse> {
        const body = {
            status: status,
        };

        return this.httpClient.patch<NoContentResponse>(`general-inquiries/${generalInquiryId}`, body);
    }

    deleteGeneralInquiry(generalInquiryId: string): Observable<NoContentResponse> {
        return this.httpClient.delete<NoContentResponse>(`general-inquiries/${generalInquiryId}`);
    }
}
