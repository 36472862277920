import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { AdvertType } from '@models/backend/advert';
import { AssetType, IImage, ISelect, IUploadProgress, ImageUploadType, Orientation } from '@models/shared';
import { UploadImageService } from '@services/upload-image.service';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LeavePageDialogComponent } from '../leave-page-dialog/leave-page-dialog.component';
import { DETAILS_OPTION, FOREST_DETAILS_OPTION, TAGS_OPTION } from './options';

@Component({
    selector: 'preview-uploaded-image',
    templateUrl: './preview-uploaded-image.component.html',
    styleUrls: ['./preview-uploaded-image.component.less'],
})
export class PreviewUploadedImageComponent extends UnSubscriptionDirective implements OnInit {
    private activateRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private uploadImageService = inject(UploadImageService);
    private formBuilder = inject(UntypedFormBuilder);
    private dialog = inject(MatDialog);

    @Input() fileUploadControl: FileUploadControl;
    @Input() uploadedImages$: BehaviorSubject<IImage[]> = new BehaviorSubject([]);

    @Output() startUploadingEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Output() uploadCompletedEvent: EventEmitter<IUploadProgress[]> = new EventEmitter(null);
    @Output() backToUploadEvent: EventEmitter<void> = new EventEmitter();

    imageIndex$: ReplaySubject<number> = new ReplaySubject();

    isStartUploading: boolean = false;

    isUploadComplete: boolean = false;

    imageUploadResults: IUploadProgress[] = [];
    uploadedImages: IImage[] = [];

    items: UntypedFormArray;

    advertLink: string;

    detailsOptions: IAutocompleteData[];

    orientations: ISelect[] = [
        { id: 'landscape', label: 'IMAGE_UPLOAD_ORIENTATION.landscape' },
        { id: 'panorama', label: 'IMAGE_UPLOAD_ORIENTATION.panorama' },
        { id: 'portrait', label: 'IMAGE_UPLOAD_ORIENTATION.portrait' },
    ];

    isPropertyBuilding: boolean = false;

    type: ImageUploadType;
    assetType: AssetType;
    advertId: string;
    unitCode: string;
    advertType: AdvertType;

    imageUploadform: UntypedFormGroup = this.formBuilder.group({
        items: this.formBuilder.array([]),
    });

    imageTagsList: ISelect[] = TAGS_OPTION;

    isNotValidForm: boolean = true;

    get showExample(): boolean {
        const supportedUnitTypes: ImageUploadType[] = ['apartment', 'commercial', 'parking'];
        return supportedUnitTypes.includes(this.type);
    }

    get imageUploadformControls(): AbstractControl {
        return this.imageUploadform.controls.items;
    }

    get IsUploadValid(): boolean {
        return this.isNotValidForm || !this.uploadedImages$ || this.isStartUploading;
    }

    ngOnInit(): void {
        this.validateForm();
        this.getParamsFromRoute();
        this.detailsOptions = this.getDetailsOption();
        this.loadImagesOnebyOne();

        this.uploadedImages$.pipe(takeUntil(this.unsubscribe$)).subscribe((images: IImage[]) => {
            if (images.length > this.uploadedImages.length) {
                this.setupForm(images);
            }
        });
    }

    remove(index: number) {
        this.fileUploadControl.removeFile(this.fileUploadControl.value[index]);
        this.uploadedImages.splice(index, 1);
        this.items.removeAt(index);

        this.items.updateValueAndValidity();
        this.items.markAllAsTouched();
    }

    uploadImagesOneByOne(): void {
        if (this.uploadedImages?.length > 0) {
            this.imageIndex$.next(0);
            this.isStartUploading = true;
        }
    }

    backToAdvert(): void {
        if (!this.isStartUploading || this.isUploadComplete) {
            this.backToImageSection();
        } else {
            this.openLeavePageWarningDialog();
        }
    }

    backToImageSection(): void {
        this.router.navigate([this.advertLink], { queryParams: { section: 'imageSection' } });
    }

    backToUpload(): void {
        this.imageIndex$.next(0);
        this.isUploadComplete = false;
        this.isStartUploading = false;
        this.isNotValidForm = true;

        this.imageUploadResults = [];

        // clear form array
        this.items.clear();

        this.uploadedImages = [];
        this.fileUploadControl.clear();

        // revalidate form
        this.validateForm();
        this.backToUploadEvent.emit();
    }

    private validateForm(): void {
        this.imageUploadform
            .get('items')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((items) => {
                const imageForm = [];

                this.uploadedImages.forEach((image, index) => {
                    imageForm.push(items[index]);
                });

                this.isNotValidForm = imageForm.some(
                    (item) =>
                        !item.pictureDetail ||
                        (!item.pictureType && item.pictureType !== '' && this.isPropertyBuilding),
                );
            });
    }

    private openLeavePageWarningDialog(): void {
        const dialog = this.dialog.open(LeavePageDialogComponent);

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
                if (!data) {
                    this.backToImageSection();
                }
            });
    }

    private createFormArray(orientation: Orientation): void {
        this.items = this.imageUploadform.get('items') as UntypedFormArray;
        this.items.push(this.creatSubFormGroup(orientation));

        this.items.markAllAsTouched();
        this.items.updateValueAndValidity();
    }

    private creatSubFormGroup(orientation: Orientation): UntypedFormGroup {
        let defaultOption: ISelect | string = '';
        if (this.type === 'parking') {
            const parkingOption = this.detailsOptions.find((key) => key.id === 'parking');
            defaultOption = parkingOption;
        }

        return this.formBuilder.group({
            pictureDetail: new UntypedFormControl(defaultOption, Validators.required),
            pictureType: new UntypedFormControl('', Validators.required),
            orientation: new UntypedFormControl(orientation),
            isExample: new UntypedFormControl(false),
        });
    }

    private setupForm(images: IImage[]): void {
        this.imageUploadResults = [];
        this.isStartUploading = false;

        images.forEach((file) => {
            const doesImageExist = this.uploadedImages.some((image) => image.name === file.name);

            if (!doesImageExist) {
                this.uploadedImages.push(file);
                this.createFormArray(file.orientation);
            }

            this.imageUploadResults.push({ uploadStatus: 'start', isLoaded: false, imageName: file.name, progress: 0 });
        });
    }

    private getDetailsOption(): IAutocompleteData<string>[] {
        const availableOptions = this.advertType === 'huntingArea' ? FOREST_DETAILS_OPTION : DETAILS_OPTION;

        const availableOptionsWithTrLabels = availableOptions.map((detail) => ({
            id: detail.id,
            label: `IMAGE_LABELS.${detail.label}`,
        }));

        return availableOptionsWithTrLabels;
    }

    private getParamsFromRoute(): void {
        this.advertId = this.activateRoute.snapshot.params.unitId;
        this.advertLink = `/advert/${this.advertId}`;

        this.activateRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: Params) => {
            this.advertType = params.advertType;
            this.isPropertyBuilding = params.type === 'property/building';
            this.unitCode = params.unitCode;
            this.type = params.type;
            const unitImageUploadTypes: ImageUploadType[] = ['apartment', 'commercial', 'parking', 'hunting_area'];
            this.assetType = unitImageUploadTypes.includes(this.type) ? 'unit' : 'property';
        });
    }

    private loadImagesOnebyOne(): void {
        this.imageIndex$.pipe(takeUntil(this.unsubscribe$)).subscribe((nextImageIndex) => {
            const currentImage = this.imageUploadResults[nextImageIndex];
            if (nextImageIndex === this.uploadedImages.length) {
                this.startUploadingEvent.emit(false);
                this.uploadCompletedEvent.emit(this.imageUploadResults);
                this.isNotValidForm = true;
                this.isUploadComplete = true;
                return;
            }

            if (currentImage?.uploadStatus !== 'success') {
                const fileIndex = this.fileUploadControl.value.findIndex(
                    (file) => file.name === this.uploadedImages[nextImageIndex].name,
                );

                this.startUploadingEvent.emit(true);
                this.uploadCompletedEvent.emit([]);

                this.imageUploadResults
                    .filter((item) => item.uploadStatus === 'failed')
                    .forEach((item) => (item.isLoaded = false));

                currentImage.isLoaded = false;
                this.upload(nextImageIndex, this.fileUploadControl.value[fileIndex]);
            } else {
                nextImageIndex++;
                this.imageIndex$.next(nextImageIndex);
            }
        });
    }

    private upload(index: number, file: File): void {
        if (file) {
            const formData = this.imageUploadform.get('items').value[index];
            const currentImage = this.imageUploadResults[index];

            this.uploadImageService
                .uploadImage(file, this.advertId, this.unitCode, formData, this.assetType, this.type)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (event) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            currentImage.uploadStatus = 'inProgress';
                            currentImage.isLoaded = true;

                            currentImage.progress = Math.round((100 * event.loaded) / event.total);
                        } else if (event instanceof HttpResponse) {
                            currentImage.uploadStatus = 'success';
                            currentImage.isLoaded = true;
                            index++;
                            this.imageIndex$.next(index);
                        }
                    },
                    () => {
                        currentImage.uploadStatus = 'failed';
                        currentImage.isLoaded = false;
                        index++;
                        this.imageIndex$.next(index);
                    },
                );
        }
    }
}
