<div class="prospect-item" *ngIf="prospect" [class.disabled]="!prospectIsEditable">
    <button
        class="bookmark"
        mat-icon-button
        matTooltip="{{ bookmarkTooltipMessage | translate }}"
        clickAndKeydown
        (actionTriggered)="toggleBookmark()"
    >
        <mat-icon>{{ isProspectBookmarked ? 'star' : 'star_border' }}</mat-icon>
    </button>

    <section class="header">
        <mat-checkbox
            [disabled]="!isValidEmail"
            [ngModel]="prospect.isSelected"
            (ngModelChange)="toggleSelection($event)"
        ></mat-checkbox>
        <article class="heading">
            {{ prospect.context.name }}
        </article>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="edit()"
            matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
        </button>
    </section>

    <section class="stats">
        <div class="stat">
            <article>
                {{ prospect.context.dateOfReceipt | convertDate }}, {{ prospect.context.dateOfReceipt | convertTime }}
            </article>
            <a href="mailto:{{ prospect.context.email }}" class="link">{{ prospect.context.email }}</a>
            <mat-error class="error-msg" *ngIf="!isValidEmail"
                ><mat-icon>info</mat-icon>{{ 'VALIDATION_MESSAGES.EMAIL' | translate }}</mat-error
            >

            <phone-number-menu [phoneNumber]="prospect.context.phone | sanitazedPhoneNumber"></phone-number-menu>

            <article class="pin" *ngIf="prospect.context.personalIdentityNumber">
                <span>{{ prospect.context.personalIdentityNumber }}</span>
                <button
                    mat-icon-button
                    clickAndKeydown
                    (actionTriggered)="copyPin()"
                    #tooltip="matTooltip"
                    [matTooltip]="copyTooltipMessage"
                >
                    <mat-icon fontSet="material-icons-outlined">file_copy</mat-icon>
                </button>
            </article>
        </div>

        <div class="stat" *ngIf="prospect.context.preferredMoveInDate">
            <article>{{ 'PROSPECTS.PREFERRED_MOVE_IN_DATE' | translate }}</article>
            <article>{{ prospect.context.preferredMoveInDate | convertDate }}</article>
        </div>

        <div class="stat" *ngIf="prospect.context.netHouseholdIncome">
            <article>{{ 'PROSPECTS.NET_HOUSEHOLD_INCOME' | translate }}</article>
            <article>{{ prospect.context.netHouseholdIncome }}</article>
        </div>

        <div class="stat" *ngIf="prospect.context.householdSize">
            <article>{{ 'PROSPECTS.HOUSEHOLD_SIZE' | translate }}</article>
            <article>{{ prospect.context.householdSize }}</article>
        </div>
    </section>

    <!-- TODO: improve switch case -->
    <mat-chip-set class="pills" [ngSwitch]="statusPillType">
        <mat-chip data-test="portalName">{{ prospect.context.portalName }}</mat-chip>

        <mat-chip *ngIf="marketingChannel">{{ marketingChannel.label | translate }}</mat-chip>

        <mat-chip class="multiline-pill" color="primary" *ngSwitchCase="'multiline'">
            {{ translatedStatus | translate }}
            <br />
            {{ scheduledViewingDateTime }}
        </mat-chip>

        <mat-chip color="primary" *ngSwitchCase="'oneline'">
            {{ translatedStatus | translate }}
        </mat-chip>
    </mat-chip-set>

    <section class="message">
        <article class="heading" *ngIf="prospect.context.hasMessage" clickAndKeydown (actionTriggered)="toggleExpand()">
            <mat-icon class="no-margin-right">{{ carretIcon }}</mat-icon>
            {{ 'SHARED_COMPONENT.MESSAGE' | translate }}
        </article>
        <article class="text" *ngIf="isExpanded" [style.white-space]="'pre-wrap'">
            {{ prospect.context.message }}
        </article>
    </section>

    <section class="actions" [class.disabled]="canActivateActions">
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="copyProspect()"
            matTooltip="{{ 'SHARED_COMPONENT.COPY' | translate }}"
        >
            <mat-icon class="material-icons-outlined">copy</mat-icon>
        </button>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="setStatus()"
            matTooltip="{{ 'SHARED_COMPONENT.SET_STATUS' | translate }}"
        >
            <mat-icon class="material-icons-outlined">autorenew</mat-icon>
        </button>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="delete()"
            matTooltip="{{ 'SHARED_COMPONENT.DELETE' | translate }}"
        >
            <mat-icon>delete_outline</mat-icon>
        </button>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="reject()"
            matTooltip="{{ 'SHARED_COMPONENT.REJECT' | translate }}"
        >
            <mat-icon>not_interested</mat-icon>
        </button>
        <button [disabled]="!isValidEmail" color="primary" mat-flat-button [matMenuTriggerFor]="menu">
            {{ 'SHARED_COMPONENT.SEND' | translate }}
            <mat-icon class="no-margin-right">expand_more</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item clickAndKeydown (actionTriggered)="writeEmail()">
                <mat-icon class="material-icons">mail_outline</mat-icon>
                {{ 'SHARED_COMPONENT.NEW_EMAIL' | translate }}
            </button>
            <button
                *ngIf="isIosDevice && prospectHasPhoneNumber"
                mat-menu-item
                clickAndKeydown
                (actionTriggered)="sendSms()"
            >
                <mat-icon class="material-icons">sms_outline</mat-icon>
                {{ 'PROSPECTS.NEW_SMS' | translate }}
            </button>
            <prospect-viewing-actions
                (setViewingInvitation)="viewingInvitation()"
                (setConfirmation)="confirmation()"
            ></prospect-viewing-actions>
        </mat-menu>
    </section>
</div>
