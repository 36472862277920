import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AutocompleteComponent } from '@components/shared/autocomplete/autocomplete.component';
import { IContactDetails } from '@models/backend/contacts';
import { ICostCenterAssignments } from '@models/backend/rotation';
import { RotationService } from '@services/rotation.service';
import { BehaviorSubject, EMPTY, Observable, catchError, finalize, takeUntil, timer } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { RotationConfirmationDialogComponent } from '../rotation-confirmation-dialog/rotation-confirmation-dialog.component';
import { IRefreshAssignmentsData } from '../types';

type AssignmentsStatus = 'success' | 'error' | 'warning';
@Component({
    selector: 'rotation-list-item',
    templateUrl: './rotation-list-item.component.html',
    styleUrls: ['./rotation-list-item.component.less'],
})
export class RotationListItemComponent extends UnSubscriptionDirective implements OnChanges {
    private dialog = inject(MatDialog);
    private rotationService = inject(RotationService);

    @Input() contacts: IContactDetails[] = [];
    @Input() costCentersAssignments$: Observable<ICostCenterAssignments[]> = EMPTY;
    @Output() scrollEventTriggered = new EventEmitter<void>();
    @Output() refresh = new EventEmitter<IRefreshAssignmentsData>();

    @ViewChild(LoadingIndicatorComponent, { static: true })
    loadingIndicator: LoadingIndicatorComponent;

    @ViewChild('autocomplete')
    autocomplete: AutocompleteComponent<IAutocompleteData>;

    assignedLM: string | null;

    assignedLMForm = new UntypedFormControl();

    selectedCostCenters: ICostCenterAssignments[] = [];
    contactsAutocompleteItems: IAutocompleteData[] = [];

    status: AssignmentsStatus;
    status$ = new BehaviorSubject<AssignmentsStatus>('success');

    get disableAssignmentsSelection(): boolean {
        return this.selectedCostCenters?.length === 0;
    }

    get isAutoCompleteDataReady(): boolean {
        return (
            this.contacts &&
            this.contactsAutocompleteItems &&
            this.contacts.length === this.contactsAutocompleteItems.length
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contacts) {
            this.selectedCostCenters = [];
            const contacts: IContactDetails[] = changes.contacts.currentValue;
            this.contacts = contacts;

            if (this.contacts) {
                const contactAutocompleteItems = this.contacts.map((contact: IContactDetails) => ({
                    id: contact.id,
                    label: `${contact.contactFirstName} ${contact.contactLastName}`,
                }));

                timer(0)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.contactsAutocompleteItems = [...contactAutocompleteItems];
                    });
            }
        }
    }

    onScroll(isLast: boolean): void {
        if (isLast) {
            this.selectedCostCenters = [];
            this.scrollEventTriggered.emit();
        }
    }

    assignLettingManagerToCostCenterRequest(selectedLm: IContactDetails): void {
        const costCenterCode: string[] = this.selectedCostCenters.map((costCenter) => costCenter.costCenterCode);
        this.loadingIndicator.show();

        this.rotationService
            .assignLMToCostCenters(selectedLm.id, costCenterCode)
            .pipe(
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
                catchError(() => {
                    this.status$.next('error');
                    this.openDialog(selectedLm);
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.status$.next('success');
                this.refresh.emit({
                    costCenterCode,
                    selectedLm: selectedLm,
                });
                this.openDialog(selectedLm);
                this.selectedCostCenters = [];
            });
    }

    assignLettingManagerToCostCenterDialog(event: MatAutocompleteSelectedEvent): void {
        if (!event.option.value || !this.selectedCostCenters.length) {
            this.resetAssignments();
            return;
        }

        const selectedLm = this.contacts.find((lm) => lm.id === event.option.value.id);

        this.status$.next('warning');
        this.openDialog(selectedLm);
    }

    openDialog(selectedLm: IContactDetails): void {
        const dialog = this.dialog.open(RotationConfirmationDialogComponent, {
            data: { ...selectedLm, status$: this.status$ },
            panelClass: 'unconstrained-dialog',
            height: '300px',
        });

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((confirmation) => {
                if (confirmation) {
                    this.assignLettingManagerToCostCenterRequest(selectedLm);
                }

                this.resetAssignments();
            });
    }

    resetAssignments(): void {
        this.assignedLMForm.patchValue({
            id: null,
            label: null,
        });

        this.autocomplete.reset();

        timer(0)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.autocomplete.inputAutoComplete.closePanel();
            });
    }

    toggle(costCentersAssignment: ICostCenterAssignments, event: MatCheckboxChange) {
        if (!event) {
            return null;
        }

        if (event.checked) {
            this.selectedCostCenters.push(costCentersAssignment);
        } else {
            const index = this.selectedCostCenters.indexOf(costCentersAssignment);
            if (index >= 0) {
                this.selectedCostCenters.splice(index, 1);
            }
        }
    }

    exists(costCentersAssignment: ICostCenterAssignments): boolean {
        return this.selectedCostCenters.includes(costCentersAssignment);
    }

    isIndeterminate(costCentersAssignments: ICostCenterAssignments[]): boolean {
        return this.selectedCostCenters.length > 0 && !this.isChecked(costCentersAssignments);
    }

    isChecked(costCentersAssignments: ICostCenterAssignments[]): boolean {
        return this.selectedCostCenters.length === costCentersAssignments.length;
    }

    toggleAll(event: MatCheckboxChange, costCentersAssignments: ICostCenterAssignments[]): void {
        if (!event) {
            return null;
        }

        if (event.checked) {
            costCentersAssignments.forEach((row) => {
                this.selectedCostCenters.push(row);
            });
        } else {
            this.selectedCostCenters.length = 0;
        }
    }

    trackByCostCenterCode(_index: number, costCentersAssignments: ICostCenterAssignments): string {
        return costCentersAssignments.costCenterCode;
    }
}
