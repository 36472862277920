import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipComponent } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { getSourceOptions } from '@helpers/marketing-source-data.helper';
import { TimeHelper } from '@helpers/time.helper';
import { SupportedCountryCode } from '@models/backend/common';
import { ProspectStatus } from '@models/backend/prospects';
import { ExpandableElement } from '@models/common/expandable-element';
import { Selectable } from '@models/common/selectable';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { TranslateService } from '@ngx-translate/core';
import { ConvertDatePipe } from '@pipes/convert-date.pipe';
import { AnalyticsService } from '@services/analytics.service';
import { UserSettingsService } from '@services/user-settings.service';
import { EMAIL_REGEX } from '@validators/regex';
import { takeUntil, timer } from 'rxjs';
import { ProspectCopyComponent } from '../prospect-copy/prospect-copy.component';
import { ProspectCopyModalArgs } from '../prospect-copy/types';

type StatusPillType = 'oneline' | 'multiline';

interface IScheduledDateTime {
    processedDate: string;
    processedTime: string;
}

@Component({
    selector: 'prospect-item',
    templateUrl: 'prospect-item.component.html',
    styleUrls: ['prospect-item.component.less'],
})
export class ProspectItemComponent extends ExpandableElement {
    private userSettingsService = inject(UserSettingsService);
    private clipboard = inject(Clipboard);
    private translateService = inject(TranslateService);
    private convertDatePipe = inject(ConvertDatePipe);
    private gaService = inject(AnalyticsService);
    private activeRouter = inject(ActivatedRoute);

    dialog = inject(MatDialog);

    @Input()
    prospect: Selectable<ProspectViewModel>;
    @Input()
    canActivateActions: boolean;
    @Input()
    prospectIsEditable = false;
    @Input()
    countryCode: SupportedCountryCode;
    @Input()
    region: string;

    @Output()
    setViewingInvitation = new EventEmitter<ProspectViewModel>();

    @Output()
    setConfirmation = new EventEmitter<ProspectViewModel>();

    @Output()
    emailSelected = new EventEmitter<ProspectViewModel>();

    @Output()
    smsSelected = new EventEmitter<ProspectViewModel>();

    @Output()
    editClicked = new EventEmitter<ProspectViewModel>();

    @Output()
    prospectDeleted = new EventEmitter<ProspectViewModel>();

    @Output()
    prospectRejected = new EventEmitter<ProspectViewModel>();

    @Output()
    selectionChanged = new EventEmitter<ProspectViewModel>();

    @Output()
    bookmarkChanged = new EventEmitter<ProspectViewModel>();

    @Output()
    statusChanged = new EventEmitter<ProspectViewModel>();

    @ViewChild('tooltip') private copyTooltip: TooltipComponent;

    private isCopied = false;

    get isIosDevice(): boolean {
        return this.userSettingsService.getOperatingSystem() === 'iOS';
    }

    get prospectHasPhoneNumber(): boolean {
        return !!this.prospect.context.phone;
    }

    get isScheduledViewingDateSet(): boolean {
        return (
            !!this.prospect.context.scheduledViewingEventDate && !!this.prospect.context.scheduledViewingEventStartTime
        );
    }

    get copyTooltipMessage(): string {
        const tooltip = this.isCopied ? 'SHARED_COMPONENT.COPIED' : 'SHARED_COMPONENT.COPY';
        return this.translateService.instant(tooltip);
    }

    get translatedStatus(): string {
        return `KEY_FACT_ENUMS.prospectStatus.${this.prospect.context.status}`;
    }

    get statusPillType(): StatusPillType {
        if ((this.hasScheduledViewing && this.isScheduledViewingDateSet) || this.hasViewingAttended) {
            return 'multiline';
        }

        return 'oneline';
    }

    get hasScheduledViewing(): boolean {
        return this.prospect.context.status === ProspectStatus.ViewingScheduled;
    }

    get hasViewingAttended(): boolean {
        return this.prospect.context.status === ProspectStatus.ViewingAttended;
    }

    get scheduledViewingDateTime(): string {
        const { processedDate, processedTime } = this.getDateAndTime(this.prospect.context);
        return `${processedDate}, ${processedTime}`;
    }

    get isProspectBookmarked(): boolean {
        if (this.prospectIsEditable) {
            return this.userSettingsService.isProspectBookmarked(this.prospect.context.id);
        }
        return false;
    }

    get bookmarkTooltipMessage(): string {
        return this.isProspectBookmarked ? 'SHARED_COMPONENT.REMOVE_BOOKMARK' : 'SHARED_COMPONENT.BOOKMARK';
    }

    get isValidEmail(): boolean {
        const { email, phone } = this.prospect.context;
        if (!email && phone) {
            return true;
        }

        return EMAIL_REGEX.test(email);
    }

    get marketingChannel(): IAutocompleteData {
        const sources = getSourceOptions(this.countryCode);
        const marketingChannelObj = sources.find((source) => source.id === this.prospect.context.marketingChannel);
        return marketingChannelObj;
    }

    writeEmail(): void {
        this.gaService.event(GoogleAnalyticsEvents.ProspectAnswered);
        this.emailSelected.emit(this.prospect.context);
    }

    edit(): void {
        this.editClicked.emit(this.prospect.context);
    }

    delete(): void {
        this.prospectDeleted.emit(this.prospect.context);
    }

    reject(): void {
        this.prospectRejected.emit(this.prospect.context);
    }

    setStatus(): void {
        this.statusChanged.emit(this.prospect.context);
    }

    toggleBookmark(): void {
        if (this.isProspectBookmarked) {
            this.userSettingsService.removeProspectBookmark(this.prospect.context.id);
        } else {
            this.userSettingsService.addProspectBookmark(this.prospect.context.id);
        }

        this.userSettingsService
            .updateBookmarkedProspects()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.bookmarkUpdated());
    }

    viewingInvitation(): void {
        this.setViewingInvitation.emit(this.prospect.context);
    }

    confirmation(): void {
        this.setConfirmation.emit(this.prospect.context);
    }

    private bookmarkUpdated(): void {
        this.bookmarkChanged.emit(this.prospect.context);
    }

    toggleSelection(isSelected: boolean): void {
        this.prospect.isSelected = isSelected;
        this.selectionChanged.emit(this.prospect.context);
    }

    copyProspect(): void {
        const sourceAdvertId = this.activeRouter.snapshot.params.unitId;
        const args: ProspectCopyModalArgs = {
            sourceAdvertId,
            prospects: [this.prospect.context],
        };
        const dialog = this.dialog.open(ProspectCopyComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }
            });
    }

    copyPin(): void {
        this.clipboard.copy(this.prospect.context.personalIdentityNumber);
        this.isCopied = true;

        timer(0)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.copyTooltip.show(0);
            });

        timer(3000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.copyTooltip.hide(0);
                this.isCopied = false;
            });
    }

    sendSms(): void {
        this.smsSelected.emit(this.prospect.context);
    }

    private getDateAndTime(prospect: ProspectViewModel): IScheduledDateTime {
        const { scheduledViewingEventDate: date, scheduledViewingEventStartTime: time } = prospect;
        const processedTime = TimeHelper.getInputFormattedValue(time) || '-';
        return {
            processedDate: this.convertDatePipe.transform(date),
            processedTime,
        };
    }
}
